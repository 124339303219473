<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'newSupplier'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Add New Supplier
            </h5>
            <a
              class="close"
              title="Close"
              @click="$store.dispatch('modalClose', 'addNewItem')"
            >
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form @submit.prevent="submit" class="bg-theam-secondary">
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-5 mt-0 mb-1">
                            <input
                              type="file"
                              id="Supplier_image"
                              style="display: none"
                              @change="base64Image"
                            />
                            <label
                              for="Supplier_image"
                              class="popup-img-container"
                            >
                              <span
                                >Browse <br />
                                Image</span
                              >
                              <div class="popup_img">
                                <img
                                  class="popup_img"
                                  :src="
                                    formData.image
                                      ? formData.image
                                      : '/assets/img/logosmall.png'
                                  "
                                />
                                <div id="change_img">
                                  Change <br />
                                  Image
                                </div>
                              </div>
                            </label>
                          </div>
                          <div class="col-md-7 pl-0 pd-t-30 pb-0">
                            <div class="row">
                              <div class="col-md-5 mr-0 pr-0">Supplier ID</div>
                              <div class="col-md-7 ml-0 pl-0">
                                <input
                                  class="form-control"
                                  id="supplier_id"
                                  placeholder="id"
                                  disabled
                                  v-model="formData.supplier_id"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Supplier Name *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              class="form-control"
                              id="supplier_name"
                              placeholder="Supplier Name"
                              v-model="formData.name"
                              required
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Address</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              class="form-control"
                              id="supplier_address"
                              placeholder="Address"
                              v-model="formData.address"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">PAN / VAT No.</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              class="form-control"
                              id="supplier_pan_vat_no"
                              placeholder="PAN No."
                              v-model="formData.pan_vat_no"
                              @keyup="checkPanValidation"
                            />
                             <span class="unique-message" v-if="pan_error_msg == true">Pan number must be 9 digits </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">Contact Detail</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Phone No</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="input"
                              placeholder="Phone No."
                              class="form-control"
                              id="supplier_phone_no"
                              v-model="formData.phone"
                              maxlength="25"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Email</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="email"
                              placeholder="Email"
                              class="form-control"
                              id="supplier_email"
                              v-model="formData.email"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Website</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="url"
                              placeholder="https://softechpark.com/"
                              class="form-control"
                              id="supplier_website"
                              v-model="formData.website"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Contact Person</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Name</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              name="expense_amount"
                              class="form-control"
                              id="supplier_contact_person_name"
                              placeholder="Name"
                              v-model="formData.contact_person_name"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Designation</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              class="form-control"
                              id="supplier_designation"
                              placeholder="Director"
                              v-model="formData.contact_person_designation"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Contact No.</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              class="form-control"
                              id="supplier_contact_no"
                              placeholder="98xxxxxxxx"
                              v-model="formData.contact_person_phone"
                              maxlength="25"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group mb-3">
                      <label class="group-label">Amount Detail</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Opening Balance</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              step="any"
                              class="form-control"
                              id="supplier_opening_balance"
                              placeholder="NPR 0.00"
                              v-model="formData.opening_due"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="group" id="description">
                      <label class="group-label">Description</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-12">
                            <textarea
                              class="form-control"
                              id="supplier_description"
                              type="text"
                              style="height: 4.7rem"
                              v-model="formData.description"
                              maxlength="250"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton">
                  {{ disableSubmitButton ? 'Submitting....' : 'Submit'}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
export default {
  data() {
    return {
      formData: {
          'supplier_id':null,
          'image':null,
          'name':'',
          'phone':null,
          'address':null,
          'email':'',
          'pan_vat_no':'',
          'website':'',
          'contact_person_name':'',
          'contact_person_designation':'',
          'contact_person_phone':'',
          'contact_person_email':'',
          'opening_due':'',
          'description':null
      },
      disableSubmitButton:false,
      pan_error_msg:false,
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists4",
      "dataId",
      "modalId",
      "modalMode",
    ]),
    ...mapGetters("suppliers",["supplierDatas"])
  },
  methods: {
    base64Image(e) {
      this.formData.image = null;
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.formData.image = e.target.result;
      };
    },
    submit() {
      if (this.modalMode == "create") {
        this.loading = true; 
        this.disableSubmitButton = true,
        Services.createNewSupplier(this.formData).then(res=>{
            this.$store.commit("suppliers/setSupplierDatas",res.data.data.data);
            this.loading=false;
            this.$store.dispatch("modalClose");
            this.setNotification(res.data.success_message);
            this.disableSubmitButton= false;
            this.$emit('parent-event');
        }).catch(err=>{
            this.error=false;
            this.disableSubmitButton = false;
            console.log(err);
            if(err.response.status == 422){
                let error = Object.values(err.response.data.errors).flat();
                this.setNotification(error);
             }else{
                let error = err.response.data.error_message;
                this.setNotification(error);
                console.log(err);
             }
        });
      }
    },
    setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
     checkPanValidation(){
         if(!isNaN(this.formData.pan_vat_no) && this.formData.pan_vat_no != '' && this.formData.pan_vat_no != null){
            if(this.formData.pan_vat_no.length != 9 || this.formData.pan_vat_no < 0){
                this.disableSubmitButton = true;
                this.pan_error_msg = true;
            }else{
              this.disableSubmitButton = false;
              this.pan_error_msg = false;
            }
          }else{
            this.disableSubmitButton = false;
            this.pan_error_msg = false;
          }
      },
    clearData() {
          this.formData.supplier_id=null;
          this.formData.image=null;
          this.formData.name='';
          this.formData.phone=null;
          this.formData.address=null;
          this.formData.email='';
          this.formData.pan_vat_no='';
          this.formData.website='';
          this.formData.contact_person_name='';
          this.formData.contact_person_designation='';
          this.formData.contact_person_phone='';
          this.formData.contact_person_email='';
          this.formData.opening_due='';
          this.formData.description=null;
    },
  },
  watch: {
    modalId() {
      if(this.modalId == 'newSupplier'){
        this.$store.commit("getData4", `api/suppliers/create`);
      }
    },
    dataLists4(value){
      if(this.modalMode == "create"){
        if (value.length != 0) {
        this.formData.supplier_id = `${value.supplier_id}`;
      }
      }
    },
    
    // eventMessage(value) {
    //   if (value.indexOf("success") >= 0) {
    //     this.clearData();
    //   }
    // },
    modalMode(value) {
      if (value == "create") {
        this.clearData();
      }
    },
  },
};
</script>
<style scoped>
  .unique-message{
    color: #DB4437;
  }
</style>